import React from 'react';
import SitInFrontOfWhiteboard from '../animations/sit-in-front-of-whiteboard'
import DrawOnWhiteboard from '../animations/draw-on-whiteboard'

class HomeContent extends React.Component {

  bookmark() {
    // Mozilla Firefox Bookmark
    if ('sidebar' in window && 'addPanel' in window.sidebar) {
        window.sidebar.addPanel(window.location.href,document.title,"");
    } else if( /*@cc_on!@*/false) { // IE Favorite
        window.external.AddFavorite(window.location.href,document.title);
    } else { // webkit - safari/chrome
        alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') !== - 1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
    }
  }

  render() {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row my-5 md:my-16 md:-mx-5 items-center">
          <div className="w-full md:w-2/5 px-5 sit-in-front-of-whiteboard">
            <SitInFrontOfWhiteboard />
          </div>

          <div className="px-5 w-full md:w-3/5">
            <div className="flex-col bg-white shadow rounded p-5 md:p-8">
              <div className="flex flex-col">
                <h2 className="mt-1 mb-2 uppercase text-3xl" style={{ order: 1 }}>Helping Software & Web Devs Stay Top Of Their Game</h2>

                <p className="opacity-50" style={{ order: 0 }}>SEE WHAT THE BEST ARE USING</p>
              </div>

              <p className="mb-2">Wouldn't it be great if a wagon could just roll down your street and unload a set of software tools that the best web developers are using to get ahead? Now you can with the software development tools that Software Wagon have sourced for you.</p>

              <p className="mb-2">Covering every aspect of software development from design and photography, to code editors and front-end technologies to SEO and hosting resources, we've got you covered.</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row my-5 md:my-16 md:-mx-5 items-center">
          <div className="w-full md:w-2/5 px-5 order-0 draw-on-whiteboard">
            <DrawOnWhiteboard />
          </div>

          <div className="px-5 w-full md:w-3/5 order-1">
            <div className="flex-col bg-white shadow rounded p-5 md:p-8">
              <div className="flex flex-col">
                <h2 className="mt-1 mb-2 uppercase text-3xl" style={{ order: 1 }}>Curated By Experts. We'll Keep Your Developer Toolkit Sharp</h2>

                <p className="opacity-50" style={{ order: 0 }}>CONTINUOUSLY IMPROVING THE LIST</p>
              </div>

              <p className="mb-2">There are many different paths to take when it comes to building websites or applications but there is one thing to remember about a wagon, it has an open top. Sometimes things fall out but it always makes room for another tool or resource along the way.</p>
              <p className="mb-2">You'll no longer need to maintain your own bookmarks and favourites lists as we're actively collecting the latest and greatest lists of tools and resources that have been socially proofed, and curated by experts in software development.</p>
              <p>If you are keen to come along on this journey with us, then please, <button className="text-blue" onClick={this.bookmark}>bookmark us</button> or give us a shout out and jump aboard the Software Wagon.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeContent
