import React from 'react'
import { Link } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class CategoryCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isHovered: false
    };

    this.handleHover = this.handleHover.bind(this);
  }

  handleHover(state){
    this.setState({
      isHovered: ! this.state.isHovered
    });
  }

  render() {
    const categorySlug = `/${this.props.category.slug}`

    const hoverClass = this.state.isHovered ? "category-card-hovered" : "";

    return (
      <Link
        to={categorySlug}
        className={`block category-card ${hoverClass}`}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <div
          className="flex flex-col bg-white shadow-card md:mb-8 mb-4 justify-end w-full md:h-300"
        >
          <div
            className="flex justify-center items-center flex-1"
            style={{
              minHeight: `182px`,
            }}
          >
            <LazyLoadImage
              effect="blur"
              style={{
                display: `flex !important`,
              }}
              wrapperClassName="flex justify-center items-center p-4"
              alt={this.props.category.name}
              src={`/images/categories/svg/${this.props.category.svg_image_name}`}
            />
          </div>

          <span className="category-name text-center uppercase bg-grey p-4 text-text-darkest text-sm md:text-base rounded-b">
            {this.props.category.name}
          </span>
        </div>
      </Link>
    )
  }
}

export default CategoryCard
