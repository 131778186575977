import React from 'react';
import { StaticQuery, graphql } from "gatsby"

import CategoryCard from './elements/category-card'

export default ({ data }) => (
  <StaticQuery
    query={query}
    render={data => {
      const categories = data.allCategoriesJson.edges.map(cat => cat.node)

      return (
        <div className="flex flex-wrap justify-center -mx-2 md:-mx-4 xl:justify-start">
          {
            categories.map((category, index) =>
              <div className="w-1/2 px-2 md:px-4 lg:w-1/4 md:w-1/3" key={index}>
                <CategoryCard category={category}/>
              </div>
            )
          }
        </div>
      )
    }}
  />
)

const query = graphql`
  {
    allCategoriesJson {
      edges {
        node {
          name
          slug
          svg_image_name
        }
      }
    }
  }
`
