import React from 'react'
import CategorySelect from './category-select'
import SitAtDeskAnimation from '../animations/sit-at-desk-animation'
import Scrollchor from 'react-scrollchor';


class HomePageHero extends React.Component {
  render() {
    // Home page won't ever have a category set
    const category = ''

    return (
      <div className="my-12 flex flex-col items-center -mx-5 md:flex-row">
        <div
          className="flex flex-col items-start px-5 w-full md:w-1/2"
          style={{
            maxWidth: '482px'
          }}
        >
          <h1 className="w-full font-normal my-2 lg:text-2xl text-xl tracking-wide" style={{ order: 1 }}>
            Lists of all the best software tools
          </h1>

          <h2 className="w-full uppercase lg:text-6xl md:text-4xl text-3xl" style={{ order: 0 }}>
            What's in your wagon?
          </h2>

          <div className="flex mt-6 w-full -mx-2" style={{ order: 2 }}>
            <div className="px-2 w-24 md:w-1/4 flex">
              <Scrollchor
                className="bg-white text-blue rounded flex uppercase text-sm justify-center items-center px-4 w-full"
                to="#categories"
                animate={{
                  offset: -30,
                  duration: 300,
                }}
              >
                View
              </Scrollchor>
            </div>

            <CategorySelect category={category}/>
          </div>
        </div>

        <div className="px-5 w-full md:w-1/2 flex-1 sit-at-desk-animation">
          <SitAtDeskAnimation />
        </div>

      </div>
    )
  }
}

export default HomePageHero
