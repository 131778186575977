import React from 'react'

import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CategoryList from '../components/category-list'
import HomePageHero from '../components/elements/home-page-hero';
import HomeContent from '../components/elements/home-content';

class Index extends React.Component {
  render() {
    const ogImage = `${this.props.data.site.siteMetadata.siteUrl}/images/software-wagon.png`;

    return (
      <Layout location={this.props.location}>
        <SEO
          title="Best Software Tool Lists"
          keywords={[`software wagon`, `software`, `resources`]}
          meta={[
            {
              property: 'og:image',
              content: ogImage
            },
            {
              property: 'twitter:image',
              content: ogImage
            },
            {
              property: 'og:url',
              content: this.props.data.site.siteMetadata.siteUrl
            }
          ]}
        />

        <HomePageHero key="header-content" />

        <HomeContent key="white-content"/>

        <h2
          id="categories"
          className="text-center mt-2 mb-10 py-8 font-normal text-text-darkest text-3xl uppercase"
        >Resources & tools - categories</h2>

        <CategoryList />
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
