import React from 'react';
import Lottie from 'lottie-react-web'
import animationData from './json/sit-at-desk-animation.json'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const Animation = () => (
  <LazyLoadComponent>
    <Lottie
      options={{
        animationData
      }}
      className="pointer-normal"
    />
  </LazyLoadComponent>
)

export default Animation
