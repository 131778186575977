import React from 'react';
import Lottie from 'lottie-react-web'
import animationData from './json/sit-in-front-of-whiteboard.json'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

class Animation extends React.Component {
  changeViewbox() {
    document.getElementById('sit-in-front-of-whiteboard')
      .querySelectorAll('svg')[0]
      .setAttribute('viewBox', '400 200 800 800')
  }

  render() {
    return (
      <div id="sit-in-front-of-whiteboard">
        <LazyLoadComponent afterLoad={this.changeViewbox}>
          <Lottie
            options={{
              animationData
            }}
            className="pointer-normal"
          />
        </LazyLoadComponent>
      </div>
    )
  }
}

export default Animation

